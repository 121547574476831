<template>
    <div class="text-left col-mx-auto col-8 col-md-10 col-sm-12">
        <div class="form" :class="{ 'has-error': errorMsg != null }">
            <div class="form-group text-left">
                <label class="form-label">Имя</label>
                <input class="form-input" type="text"
                    v-model="name" placeholder="Имя агента">
            </div>
            <div class="form-group text-left">
                <label class="form-label">Почтовый адрес</label>
                <input class="form-input" type="text"
                    v-model="email" placeholder="Почтовый адреса агента">
            </div>
            <div class="form-group text-left">
                <label class="form-label">Комментарий</label>
                <textarea class="form-input" rows="5"
                    v-model="description"
                    placeholder="Комментарий" />
            </div>
            <span class="form-input-hint">{{errorMsg}}</span>
            <div>
                <button
                    class="btn btn-primary"
                    @click="submitCreateAgent"
                    :disabled="!canCreate"
                    type="button"
                >Создать</button>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { CREATE_AGENT } from '@/store/agents/actions';

export default {
    name: 'NewAgent',
    data() {
        return {
            name: null,
            email: null,
            description: null,
            errorMsg: null,
        };
    },

    computed: {
        ...mapState('agents', { agent: 'selected' }),
        canCreate() {
            return (this.name != null && this.name.length > 0);
        },
    },

    methods: {
        ...mapActions('agents', {
            createAgent: CREATE_AGENT,
        }),

        submitCreateAgent() {
            const payload = {
                name: this.name,
                email: this.email,
                description: this.description,
            };

            this.createAgent(payload).then(() => {
                this.$router.push({
                    name: 'agent-info',
                    params: {
                        agentID: this.agent.id,
                    },
                });
            }).catch((err) => {
                this.errorMsg = err.message;
            });
        },
    },
};
</script>
