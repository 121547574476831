import Vue from 'vue';
import Vuex from 'vuex';

import Api from '@/api';

import * as A from './actions';
import * as M from './mutations';

import profile from './profile';
import owners from './owners';
import udetails from './udetails';
import agents from './agents';

Vue.use(Vuex);

function login(commit, userinfo) {
    if (userinfo.is_admin) {
        commit(M.INIT, userinfo);
        commit('profile/UPDATE_PROFILE', userinfo);
    } else {
        commit(M.SHOW_ERROR, 'Неверный логин или пароль');
    }
}

const Store = new Vuex.Store({
    // На дев версии - жестко проверяем работу стора.
    // На проде strict отключаем, так как он потребляет много ресурсов
    strict: process.env.NODE !== 'production',

    state: {
        app: {
            userID: undefined,
            errorMsg: null,
        },
    },

    getters: {
        isLogged: (state) => state.app.userID !== null && state.app.userID !== undefined,
        isNotLogged: (state) => state.app.userID === null,

        appError: (state) => state.app.errorMsg,
    },

    mutations: {
        [M.INIT](state, info) {
            state.app.userID = info.id;
            state.app.errorMsg = null;
            if (info.token) {
                Api.authToken(info.token);
            }
        },
        [M.RESET](state) {
            state.app.userID = null;
            state.app.errorMsg = null;
            Api.authToken(null);
        },
        [M.SHOW_ERROR](state, errorMsg) {
            state.app.errorMsg = errorMsg;
        },
    },

    actions: {
        [A.LOGIN]({ commit }, creditals) {
            Api.login(creditals).then((resp) => {
                login(commit, resp.data);
            }).catch((err) => {
                if (err.response.status === 401) {
                    commit(M.SHOW_ERROR, 'Неверный логин или пароль');
                } else {
                    commit(M.SHOW_ERROR, 'Произошла неизвестная ошибка');
                }
            });
        },

        [A.LOGOUT]({ commit }) {
            Api.logout().finally(() => { commit(M.RESET); });
        },
    },

    modules: {
        profile,
        owners,
        agents,
        udetails,
    },
});

// Устанавливаем на Api фильтр аутенфикации
Api.addErrorFilter((error) => {
    if (error.response.status === 401) {
        Store.commit(M.RESET);
    }
    return Promise.reject(error);
});

// При старте всегда проверяем текущий статус пользователя
Api.profile().then((resp) => {
    login(Store.commit, resp.data);
}).catch((e) => {
    Store.commit(M.RESET);
    if (e.response && e.response.status === 401) {
        return;
    }
    Store.commit(M.SHOW_ERROR, e.message);
});

export default Store;
