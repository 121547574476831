import { mapActions, mapState } from 'vuex';

import * as A from '@/store/agents/actions';

import AppLoading from '@/components/common/AppLoading.vue';
import RemoveConfirmModal from '@/components/common/RemoveConfirmModal.vue';

import AgentCodeModal from './AgentCodeModal.vue';
import AgentCodeRef from './AgentCodeRef.vue';

function cloneInfo(info) {
    return {
        name: info.name,
        email: info.email,
        description: info.description,
    };
}

export default {
    name: 'AgentInfo',
    props: ['agentID'],

    components: {
        AppLoading, RemoveConfirmModal, AgentCodeModal, AgentCodeRef,
    },

    watch: {
        agentID() {
            this.reload();
        },
    },

    data() {
        return {
            isLoading: false,
            info: null,
        };
    },

    created() {
        this.reload();
    },

    computed: {
        ...mapState('agents', {
            agent: 'selected',
        }),

        changed() {
            return this.agent != null && this.info != null
                && (this.agent.name !== this.info.name
                || this.agent.email !== this.info.email
                || this.agent.description !== this.info.description);
        },
    },

    methods: {
        ...mapActions('agents', {
            fetchAgent: A.SELECT_AGENT,
            updateAgent: A.UPDATE_AGENT,
            removeAgent: A.REMOVE_AGENT,
            createCode: A.CREATE_AGENT_CODE,
            removeCode: A.REMOVE_AGENT_CODE,
        }),

        reload() {
            if (!this.agentID) {
                this.$router.replace({ name: 'agents-list' });
            }
            this.isLoading = true;
            this.fetchAgent(this.agentID).then(() => {
                this.info = cloneInfo(this.agent);
                this.isLoading = false;
            }).catch(() => {
                this.$router.replace({ name: 'agents-list' });
            });
        },

        submitUpdateAgent() {
            const payload = {
                name: this.info.name,
                email: this.info.email,
                description: this.info.description,
            };

            this.updateAgent({ id: this.agentID, payload }).then(() => {
                this.info = cloneInfo(this.agent);
            });
        },

        submitRemoveAgent() {
            this.removeAgent(this.agentID).then(() => {
                this.$router.replace({ name: 'agents-list' });
            });
        },

        submitCreateAgentCode(payload) {
            this.createCode({ id: this.agentID, payload }).then(() => {
                this.info = cloneInfo(this.agent);
            });
        },

        submitRemoveAgentCode(codeID) {
            this.removeCode({ id: this.agentID, codeID }).then(() => {
                this.info = cloneInfo(this.agent);
            });
        },
    },
};
