<template>
    <div class="form text-left">
        <div class="h4 text-center">Изменение текущего тарифа</div>

        <tariff-form-item v-model="tariff" :list="availabledTariffs"
            @change="update()"></tariff-form-item>

        <number-form-item v-model="executors" :min="minExecutors"
            @change="update()">исполнителей</number-form-item>

        <number-form-item v-model="managers" :min="minManagers"
            @change="update()">менеджеров</number-form-item>

        <number-form-item v-model="opentasks" :min="minOpenTasks" :step="100"
            @change="update()">открытых исполнений</number-form-item>

        <number-form-item v-model="fs_size" :min="minFS"
            @change="update()">ГБ файлового пространства</number-form-item>

        <boolean-form-item v-model="expert" :disabled="hasExpert"
            @change="update()">Помощь эксперта</boolean-form-item>

        <div class="divider"></div>

        <div class="container">
            <div class="columns my-2" v-if="receiptList">
                <div class="col-12 h5 text-center">Чек платежа</div>
                <div class="col-12" v-for="(receipt, idx) in receiptList.refs" :key="idx">
                    <receipt-info :receipt="receipt" />
                    <div class="divider"></div>
                </div>
                <div class="col-12 container">
                    <div class="columns">
                        <div class="col-mr-auto text-bold">Итого</div>
                        <div class="col-ml-auto text-right text-bold">
                            {{receiptList.total}} руб.</div>
                    </div>
                </div>
            </div>
            <div class="columns">
                <div class="col-mr-auto">
                    <button class="btn btn-error" @click="close()">Отмена</button>
                </div>
                <div class="col-ml-auto" v-if="receiptList" @click="submit()">
                    <button class="btn btn-success">Создать платеж</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { COST_UPGRADE_PAYMENT, CLEAN_PAYMENTS, CREATE_UPGRADE_PAYMENT } from '@/store/udetails/actions';

import TariffFormItem from './TariffFormItem.vue';
import NumberFormItem from './NumberFormItem.vue';
import BooleanFormItem from './BooleanFormItem.vue';

import ReceiptInfo from './ReceiptInfo.vue';

export default {
    name: 'PaymentUpgradeForm',

    props: ['owner', 'tariffs'],
    components: {
        TariffFormItem, NumberFormItem, BooleanFormItem, ReceiptInfo,
    },

    computed: {
        ...mapState('udetails', {
            receiptList: 'upgradeReceipt',
        }),

        availabledTariffs() {
            const nowCode = this.owner.currentStatus.tariff_code;
            const nowCost = this.tariffs.find((t) => t.code === nowCode).cost;
            return this.tariffs.filter((t) => t.can_buy && t.cost >= nowCost);
        },

        minExecutors() {
            return Math.max(this.tariff.include_executors, this.owner.currentStatus.executor_count);
        },
        minManagers() {
            return Math.max(this.tariff.include_managers, this.owner.currentStatus.manager_count);
        },
        minOpenTasks() {
            return Math.max(this.tariff.include_opentasks * 100,
                this.owner.currentStatus.opentasks_count);
        },
        minFS() {
            return Math.max(this.tariff.include_fs_size, this.owner.currentStatus.fs_size);
        },
        hasExpert() {
            return this.owner.currentStatus.has_expert;
        },
    },

    data() {
        const nowCode = this.owner.currentStatus.tariff_code;

        return {
            tariff: this.tariffs.find((t) => t.code === nowCode),
            executors: this.owner.currentStatus.executor_count,
            managers: this.owner.currentStatus.manager_count,
            opentasks: this.owner.currentStatus.opentasks_count,
            fs_size: this.owner.currentStatus.fs_size,
            expert: this.owner.currentStatus.has_expert,
        };
    },

    methods: {
        ...mapActions('udetails', {
            updateReceipt: COST_UPGRADE_PAYMENT,
            cleanReceipt: CLEAN_PAYMENTS,
            createPayment: CREATE_UPGRADE_PAYMENT,
        }),

        payload() {
            return {
                tariff: this.tariff.code,
                executors_count: this.executors,
                managers_count: this.managers,
                opentasks_count: this.opentasks,
                fs_size: this.fs_size,
                has_expert: this.expert,
            };
        },

        update() {
            if (this.executors < this.minExecutors) {
                this.executors = this.minExecutors;
            }
            if (this.managers < this.minManagers) {
                this.managers = this.minManagers;
            }
            if (this.opentasks_count < this.minOpenTasks) {
                this.opentasks_count = this.minOpenTasks;
            }
            if (this.fs_size < this.minFS) {
                this.fs_size = this.minFS;
            }

            this.updateReceipt(this.payload());
        },

        close() {
            this.cleanReceipt();
            this.$emit('close');
        },

        submit() {
            this.createPayment(this.payload()).then(() => {
                this.$emit('close');
            });
        },
    },
};
</script>
