import Api from '@/api';
import PagedList from '@/store/pagedlist';

import * as A from './actions';
import * as M from './mutations';

const page = new PagedList('pages');

const initState = {
    list: [],
    pages: page.initState(),
    filters: {},
    selected: null,
};

const getters = { };

const mutations = {
    [M.LOAD](state, { list, pagination, filters }) {
        state.list = list;
        state.pages = page.readState(pagination);
        state.filters = filters;
    },
    [M.RESET](state) {
        state.list = [];
        state.pages = page.initState();
        state.filters = {};
        state.selected = null;
    },
    [M.SELECT](state, agent) {
        state.selected = agent;
    },
};

const actions = {
    ...page.mapActions(),

    [A.UPDATE_LIST]({ dispatch, state }) {
        return page.obsolete(state) ? dispatch(A.LOAD_LIST) : Promise.resolve();
    },

    [A.LOAD_LIST]({ commit, state }, opts) {
        const pageOpts = page.pageOpts(state, opts);
        return new Promise((resolve, reject) => {
            Api.listReferAgents(pageOpts).then((resp) => {
                commit(M.LOAD, resp.data);
                resolve(resp.data);
            }).catch((err) => {
                commit(M.RESET);
                reject(err);
            });
        });
    },

    [A.SELECT_AGENT]({ commit }, id) {
        return new Promise((resolve, reject) => {
            Api.fetchReferAgent(id).then((resp) => {
                commit(M.SELECT, resp.data);
                resolve();
            }).catch(reject);
        });
    },

    [A.CREATE_AGENT]({ commit, dispatch }, payload) {
        return new Promise((resolve, reject) => {
            Api.createReferAgent(payload).then((resp) => {
                dispatch(A.LOAD_LIST);
                commit(M.SELECT, resp.data);
                resolve();
            }).catch(reject);
        });
    },

    [A.UPDATE_AGENT]({ commit, dispatch }, { id, payload }) {
        return new Promise((resolve, reject) => {
            Api.updateReferAgent(id, payload).then((resp) => {
                dispatch(A.LOAD_LIST);
                commit(M.SELECT, resp.data);
                resolve();
            }).catch(reject);
        });
    },

    [A.REMOVE_AGENT]({ commit, dispatch }, id) {
        return new Promise((resolve, reject) => {
            Api.removeReferAgent(id).then(() => {
                commit(M.SELECT, null);
                dispatch(A.LOAD_LIST);
                resolve();
            }).catch(reject);
        });
    },

    [A.CREATE_AGENT_CODE]({ commit }, { id, payload }) {
        return new Promise((resolve, reject) => {
            Api.createReferCode(id, payload).then((resp) => {
                commit(M.SELECT, resp.data);
                resolve();
            }).catch(reject);
        });
    },

    [A.REMOVE_AGENT_CODE]({ commit }, { id, codeID }) {
        return new Promise((resolve, reject) => {
            Api.removeReferCode(id, codeID).then((resp) => {
                commit(M.SELECT, resp.data);
                resolve();
            }).catch(reject);
        });
    },
};

export default {
    namespaced: true,
    state: initState,
    getters,
    actions,
    mutations,
};
