<template>
    <div class="owner-info col-mx-auto">
        <app-loading v-if="isLoading" />
        <div v-else class="columns">
            <div class="column col-6 col-md-12">
                <owner-panel :info="ownerInfo" />
                <div class="my-2" />
                <billing-panel :owner="ownerInfo" />
            </div>
            <div class="column col-6 col-md-12">
                <owner-payments />
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.owner-info {
    margin-top: 20px;
    max-width: 1100px;
}
</style>

<script>
import { mapActions, mapState } from 'vuex';
import { UPDATE_INFO } from '@/store/udetails/actions';

import AppLoading from '@/components/common/AppLoading.vue';

import OwnerPanel from './OwnerPanel.vue';
import OwnerPayments from './OwnerPayments.vue';
import BillingPanel from './BillingPanel.vue';

export default {
    name: 'OwnerInfo',
    components: {
        AppLoading, OwnerPanel, BillingPanel, OwnerPayments,
    },

    props: ['ownerID'],

    computed: {
        ...mapState('udetails', {
            ownerInfo: 'details',
        }),
    },

    data() {
        return {
            isLoading: false,
        };
    },

    created() {
        this.reload();
    },

    methods: {
        ...mapActions('udetails', {
            fetchOwnerInfo: UPDATE_INFO,
        }),

        reload() {
            this.isLoading = true;
            this.fetchOwnerInfo(this.ownerID).then(() => {
                this.isLoading = false;
            }).catch(() => {
                this.$router.push({ name: 'owners' });
            });
        },
    },
};
</script>
