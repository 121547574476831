<template>
    <div class="form-group">
        <label class="form-label"><slot /></label>
        <select class="form-select" :value="code" @input="oninput($event)">
            <option v-for="item in list"
                :key="item.code" :value="item.code">{{item.name}} - {{item.cost}} руб.</option>
        </select>
    </div>
</template>

<script>
export default {
    name: 'TariffFormItem',
    props: ['value', 'list'],

    data() {
        return {
            code: this.value.code,
        };
    },

    methods: {
        oninput(e) {
            this.code = e.target.value;
            const tariff = this.list.find((t) => t.code === this.code);
            this.$emit('input', tariff);
            this.$emit('change');
        },
    },
};
</script>
