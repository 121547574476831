<template>
    <div class="columns form-group">
        <label class="form-switch col-mx-auto">
            <input type="checkbox" v-model="vv"
                :disabled="disabled" @change="onchange()">
            <i class="form-icon"></i> <slot />
        </label>
    </div>
</template>

<script>
export default {
    name: 'NumberFormItem',
    props: {
        value: Boolean,
        disabled: Boolean,
    },

    data() {
        return {
            vv: this.value,
        };
    },

    watch: {
        value(v) {
            this.vv = v;
        },

    },

    methods: {
        onchange() {
            this.$emit('input', this.vv);
            this.$emit('change');
        },
    },
};
</script>
