import { mapGetters } from 'vuex';

import { LOGOUT } from '@/store/actions';

export default {
    name: 'AppHeader',

    computed: {
        ...mapGetters(['isLogged']),
        ...mapGetters('profile', {
            profile: 'profile',
            avatar: 'avatarURL',
        }),
    },

    methods: {
        logout() {
            this.$store.dispatch(LOGOUT);
        },
    },
};
