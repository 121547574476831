<template>
    <!-- Модальное окно изменения пароля -->
    <div class="modal" :class="{ 'active': show }">
        <div @click="hide()" class="modal-overlay" aria-label="Close"></div>
        <form class="modal-container" @submit.prevent="submit()">
            <div class="modal-header">
                <a @click="hide()" class="btn btn-clear float-right"
                    aria-label="Close"></a>
                <div class="modal-title h5">Обновить пароль</div>
            </div>
            <div class="modal-body">
                <div class="content text-left">
                    <div class="form" :class="{ 'has-error' : errorMsg != null }">
                        <div class="form-group">
                            <label class="form-label">Старый пароль</label>
                            <input class="form-input" type="password"
                                v-model="old" placeholder="Старый пароль">
                        </div>
                        <div class="form-group">
                            <label class="form-label">Новый пароль</label>
                            <input class="form-input" type="password"
                                v-model="new1" placeholder="Новый пароль">
                            <label class="form-label">Повторите новый пароль</label>
                            <input class="form-input" type="password"
                                v-model="new2" placeholder="Повторите новый пароль">
                            <p class="form-input-hint">{{errorMsg}}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button class="btn btn-primary" type="submit">Изменить пароль</button>
            </div>
        </form>
    </div>
</template>

<script>

export default {
    name: 'UpdatePasswordModal',

    data() {
        return {
            show: false,
            old: '',
            new1: '',
            new2: '',
            errorMsg: null,
        };
    },

    methods: {
        open() {
            this.show = true;
            this.old = '';
            this.new1 = '';
            this.new2 = '';
            this.errorMsg = null;
        },

        hide() {
            this.show = false;
        },

        showError(msg) {
            this.errorMsg = msg;
        },

        submit() {
            if (this.new1 !== this.new2) {
                this.errorMsg = 'Новый пароль должен быть одинаковым в обоих полях ввода';
                return;
            }
            if (this.new1.length < 1) {
                this.errorMsg = 'Пароль не может быть пустым';
                return;
            }
            this.$emit('update-password', {
                old_password: this.old,
                new_password: this.new1,
            });
        },
    },
};

</script>
