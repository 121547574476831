<template>
    <div class="text-left container my-2">
        <div class="columns">
            <div class="col-12 text-italic">Тарифный план</div>
            <div class="col-12 text-right">{{status.tariff_name}}</div>
        </div>
        <div class="columns">
            <div class="col-12 text-italic">Время действия</div>
            <div class="col-12 text-right">c {{status.start_date | formatDate}}
                по {{status.expire_date | formatDate}}</div>
        </div>
        <div class="columns">
            <div class="col-mr-auto text-italic">Количестов исполнителей</div>
            <div class="col-ml-auto">{{status.executor_count}}</div>
        </div>
        <div class="columns">
            <div class="col-mr-auto text-italic">Количестов менеджеров</div>
            <div class="col-ml-auto">{{status.manager_count}}</div>
        </div>
        <div class="columns">
            <div class="col-mr-auto text-italic">Количество исполнений</div>
            <div class="col-ml-auto">{{status.opentasks_count}}</div>
        </div>
        <div class="columns">
            <div class="col-mr-auto text-italic">Размер ФС</div>
            <div class="col-ml-auto">{{status.fs_size}}</div>
        </div>
        <div class="columns">
            <div class="col-mr-auto text-italic">Экспертная помощь</div>
            <div class="col-ml-auto">{{status.has_expert ? 'Да' : 'Hет'}}</div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'BillingStatusInfo',
    props: ['status'],
};
</script>
