import { mapState } from 'vuex';

import BillingStatusInfo from './BillingStatusInfo.vue';
import PaymentUpgradeForm from './forms/PaymentUpgradeForm.vue';
import PaymentBuyForm from './forms/PaymentBuyForm.vue';

export default {
    name: 'BillingPanel',
    props: ['owner'],

    components: { BillingStatusInfo, PaymentUpgradeForm, PaymentBuyForm },

    data() {
        return {
            upgradeForm: false,
            buyForm: false,
        };
    },

    computed: {
        ...mapState('udetails/tariffs', {
            tariffsIsLoaded: 'isLoaded',
            tariffsList: 'tariffs',
        }),

        upgradeDisabled() {
            const now = new Date().getTime();
            return this.owner.currentStatus.expire_date < now;
        },

        buyDisabled() {
            return this.owner.nextStatus != null;
        },
    },

    methods: {
        openUpgradeForm() {
            this.upgradeForm = true;
            this.buyForm = false;
        },

        openBuyForm() {
            this.upgradeForm = false;
            this.buyForm = true;
        },

        closeForms() {
            this.upgradeForm = false;
            this.buyForm = false;
        },
    },
};
