export const UPDATE_INFO = 'UPDATE_INFO';
export const CLEAN_INFO = 'CLEAN_INFO';

export const COST_BUY_PAYMENT = 'COST_BUY_PAYMENT';
export const COST_UPGRADE_PAYMENT = 'COST_UPGRADE_PAYMENT';
export const CLEAN_PAYMENTS = 'CLEAN_PAYMENTS';

export const CREATE_BUY_PAYMENT = 'CREATE_BUY_PAYMENT';
export const CREATE_UPGRADE_PAYMENT = 'CREATE_UPGRADE_PAYMENT';

export const CONFIRM_PAYMENT = 'CONFIRM_PAYMENT';
export const CANCEL_PAYMENT = 'CANCEL_PAYMENT';
