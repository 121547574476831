import { render, staticRenderFns } from "./OwnersList.vue?vue&type=template&id=00089920&scoped=true&"
import script from "./OwnersList.js?vue&type=script&lang=js&"
export * from "./OwnersList.js?vue&type=script&lang=js&"
import style0 from "./OwnersList.vue?vue&type=style&index=0&id=00089920&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "00089920",
  null
  
)

export default component.exports