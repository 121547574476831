import axios from 'axios';

const TOKEN_KEY = 'app_token';

const api = axios.create({ baseURL: '/api' });

function pageOpts(opts) {
    const params = {
        offset: 0,
        size: 10,
    };
    if (opts && opts.offset) params.offset = Number(opts.offset);
    if (opts && opts.size) params.size = Number(opts.size);

    if (opts && opts.filters) {
        Object.keys(opts.filters).forEach((key) => {
            params[key] = opts.filters[key];
        });
    }

    return params;
}

const Proxy = {
    // Функции работы с токенам аутенфикации
    authToken(token) {
        if (token) {
            api.defaults.headers.common.AUTHTOKEN = token;
            localStorage.setItem(TOKEN_KEY, token);
        } else {
            delete api.defaults.headers.AUTHTOKEN;
            localStorage.removeItem(TOKEN_KEY);
        }
    },

    // Установка фильтров
    addErrorFilter(filter) {
        api.interceptors.response.use(
            (resp) => resp,
            (error) => filter(error),
        );
    },

    // Логин/логаут
    login: (creds) => api.post('user/login', creds),
    logout: () => api.post('user/logout'),

    // Работа с профилем пользователя
    profile: () => api.get('user'),
    updateProfile: (username, payload) => api.put(`user/${username}`, payload),
    updateAvatar: (username, payload) => api.put(`user/${username}/avatar`, payload),
    updatePassword: (payload) => api.post('user/password', payload),

    // Работа с реферальными агентами
    listReferAgents: (opts) => api.get('admin/agents', { params: pageOpts(opts) }),
    fetchReferAgent: (id) => api.get(`admin/agents/${Number(id)}`),
    createReferAgent: (payload) => api.post('admin/agents', payload),
    updateReferAgent: (id, payload) => api.put(`admin/agents/${Number(id)}`, payload),
    removeReferAgent: (id) => api.delete(`admin/agents/${Number(id)}`),
    createReferCode: (id, payload) => api.post(`admin/agents/${Number(id)}/codes`, payload),
    removeReferCode: (id, codeID) => api.delete(`admin/agents/${Number(id)}/codes/${Number(codeID)}`),
    validateReferCode: (payload) => api.post('admin/agents/check', payload),

    // Работа со владельцами организаций
    listOwners: (opts) => api.get('admin/owners', { params: pageOpts(opts) }),
    ownerInfo: (id) => api.get(`admin/owners/${id}`),
    ownerPaymentsList: (id) => api.get(`admin/owners/${id}/payments`),

    costBuyPayment: (id, payload) => api.post(`admin/owners/${id}/cost/buy`, payload),
    costUpgradePayment: (id, payload) => api.post(`admin/owners/${id}/cost/upgrade`, payload),
    createBuyPayment: (id, payload) => api.post(`admin/owners/${id}/buy`, payload),
    createUpgradePayment: (id, payload) => api.post(`admin/owners/${id}/upgrade`, payload),

    confirmPayment: (id, pid) => api.post(`admin/owners/${id}/payments/${pid}`),
    cancelPayment: (id, pid) => api.delete(`admin/owners/${id}/payments/${pid}`),

    // Простые информационные методы
    listTariffs: () => api.get('admin/info/tariffs'),

    // Адрес для скачивания отчета
    exportUrl: (year, month) => `api/admin/export/owners/${Number(year)}/${Number(month)}`,
};

// Инициализация из localStorage
Proxy.authToken(localStorage.getItem(TOKEN_KEY));

export default Proxy;
