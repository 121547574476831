import { mapGetters, mapActions } from 'vuex';
import { LOGIN } from '@/store/actions';

export default {
    name: 'AppLogin',
    data() {
        return {
            username: '',
            password: '',
        };
    },
    computed: {
        ...mapGetters(['appError']),
        hasError() { return this.appError != null; },
    },
    methods: {
        ...mapActions({
            login: LOGIN,
        }),

        submitLogin() {
            this.login({ username: this.username, password: this.password });
        },
    },
};
