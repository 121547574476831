<template>
    <div class="tile tile-centered">
        <div class="tile-icon" :class="{
            'bg-success': payment.status == 'CONFIRMED',
            'bg-error': payment.status == 'CANCELED',
            'bg-primary': payment.status == 'CHECKED',
        }">
            <i class="icon centered" :class="{
                'icon-check': payment.status == 'CONFIRMED',
                'icon-stop': payment.status == 'CANCELED',
                'icon-refresh': payment.status == 'CHECKED',
                'icon-flag': payment.status == 'CREATED',
            }"></i>
        </div>
        <div class="tile-content text-left">
            <p class="tile-title">
                {{payment.type == 'BUY' ? "Продление" : "Апгрейд"}} -
                {{payment.sum}} руб.
            </p>
            <p class="tile-subtitle text-gray" v-if="payment.status == 'CONFIRMED'">
                Подтверждено {{payment.modify_time | formatDate}}
            </p>
            <p class="tile-subtitle text-gray" v-if="payment.status == 'CANCELED'">
                Отменено {{payment.modify_time | formatDate}}
            </p>
            <p class="tile-subtitle text-gray" v-if="payment.status == 'CHECKED'">
                В ожидании потдверждения {{payment.modify_time | formatDate}}
            </p>
            <p class="tile-subtitle text-gray" v-if="payment.status == 'CREATED'">
                Создано {{payment.create_time | formatDate}}
            </p>
        </div>
        <div class="tile-action" v-if="payment.status == 'CREATED'">
            <button
                @click="confirmPayment(payment.id)"
                class="btn btn-success tooltip tooltip-bottom"
                data-tooltip="Подтвердить">
                <i class="icon icon-check"></i>
            </button>
            <button
                @click="cancelPayment(payment.id)"
                class="btn btn-error  mx-2 tooltip tooltip-bottom"
                data-tooltip="Отменить">
                <i class="icon icon-stop"></i>
            </button>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.tile-icon {
    color: white;
    align-content: space-around;
    align-items: center;
    background: #5755d9;
    border-radius: .1rem;
    font-size: 1.2rem;
    height: 2rem;
    width: 2rem;
}
</style>>

<script>
import { mapActions } from 'vuex';
import { CONFIRM_PAYMENT, CANCEL_PAYMENT } from '@/store/udetails/actions';

export default {
    name: 'PaymentRef',
    props: {
        payment: Object,
    },

    methods: {
        ...mapActions('udetails', {
            confirmPayment: CONFIRM_PAYMENT,
            cancelPayment: CANCEL_PAYMENT,
        }),
    },
};
</script>
