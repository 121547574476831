<template>
    <div class="form text-left">
        <div class="h4 text-center">Продление текущего тарифа на</div>

        <number-form-item v-model="month_count" :min="1"
            @change="update()">месяцев</number-form-item>

        <div class="columns form-group">
            <label class="form-switch col-mx-auto">
                <input type="checkbox" v-model="downgrade" @change="update()">
                <i class="form-icon"></i> отказаться от части услуг
            </label>
        </div>

        <div v-if="downgrade">
            <tariff-form-item v-model="tariff" :list="availabledTariffs"
                @change="update()"></tariff-form-item>

            <number-form-item v-model="executors" :min="minExecutors" :max="maxExecutors"
                @change="update()">исполнителей</number-form-item>

            <number-form-item v-model="managers" :min="minManagers" :max="maxManagers"
                @change="update()">менеджеров</number-form-item>

            <number-form-item v-model="opentasks"
                :min="minOpenTasks" :max="maxOpenTasks" :step="100"
                @change="update()">открытых исполнений</number-form-item>

            <number-form-item v-model="fs_size" :min="minFS" :max="maxFS"
                @change="update()">ГБ файлового пространства</number-form-item>

            <boolean-form-item v-model="expert" :disabled="!hasExpert"
                @change="update()">Помощь эксперта</boolean-form-item>
        </div>

        <div class="divider"></div>

        <div class="container">
            <div class="columns my-2" v-if="receipt">
                <div class="col-12 h5 text-center">Чек платежа</div>
                <receipt-info :receipt="receipt" />
                <div class="divider"></div>
                <div class="col-12 container">
                    <div class="columns">
                        <div class="col-mr-auto text-bold">Итого</div>
                        <div class="col-ml-auto text-right text-bold">
                            {{receipt.total}} руб.</div>
                    </div>
                </div>
            </div>
            <div class="columns">
                <div class="col-mr-auto">
                    <button class="btn btn-error" @click="close()">Отмена</button>
                </div>
                <div class="col-ml-auto" v-if="receipt" @click="submit()">
                    <button class="btn btn-success">Создать платеж</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { COST_BUY_PAYMENT, CLEAN_PAYMENTS, CREATE_BUY_PAYMENT } from '@/store/udetails/actions';

import TariffFormItem from './TariffFormItem.vue';
import NumberFormItem from './NumberFormItem.vue';
import BooleanFormItem from './BooleanFormItem.vue';

import ReceiptInfo from './ReceiptInfo.vue';

export default {
    name: 'PaymentBuyForm',

    props: ['owner', 'tariffs'],
    components: {
        TariffFormItem, NumberFormItem, BooleanFormItem, ReceiptInfo,
    },

    computed: {
        ...mapState('udetails', {
            receipt: 'buyReceipt',
        }),

        availabledTariffs() {
            const nowCode = this.owner.currentStatus.tariff_code;
            const nowCost = this.tariffs.find((t) => t.code === nowCode).cost;
            return this.tariffs.filter((t) => t.can_buy && t.cost <= nowCost);
        },

        maxExecutors() {
            return Math.max(this.tariff.include_executors, this.owner.currentStatus.executor_count);
        },
        maxManagers() {
            return Math.max(this.tariff.include_managers, this.owner.currentStatus.manager_count);
        },
        maxOpenTasks() {
            return Math.max(this.tariff.include_opentasks * 100,
                this.owner.currentStatus.opentasks_count);
        },
        maxFS() {
            return Math.max(this.tariff.include_fs_size, this.owner.currentStatus.fs_size);
        },

        minExecutors() {
            return Math.max(this.tariff.include_executors, this.owner.stats.executor_count);
        },
        minManagers() {
            return Math.max(this.tariff.include_managers, this.owner.stats.manager_count);
        },
        minOpenTasks() {
            return Math.max(this.tariff.include_opentasks * 100, this.owner.stats.opentask_count);
        },
        minFS() {
            return Math.max(this.tariff.include_fs_size, this.owner.stats.fs_size);
        },

        hasExpert() {
            return this.owner.currentStatus.has_expert;
        },
    },

    data() {
        const nowCode = this.owner.currentStatus.tariff_code;

        return {
            downgrade: false,

            month_count: 1,
            tariff: this.tariffs.find((t) => t.code === nowCode),
            executors: this.owner.currentStatus.executor_count,
            managers: this.owner.currentStatus.manager_count,
            opentasks: this.owner.currentStatus.opentasks_count,
            fs_size: this.owner.currentStatus.fs_size,
            expert: this.owner.currentStatus.has_expert,
        };
    },

    created() {
        this.update();
    },

    methods: {
        ...mapActions('udetails', {
            updateReceipt: COST_BUY_PAYMENT,
            cleanReceipt: CLEAN_PAYMENTS,
            createPayment: CREATE_BUY_PAYMENT,
        }),

        payload() {
            if (!this.downgrade) {
                return {
                    month_count: this.month_count,
                    tariff: this.owner.currentStatus.tariff_code,
                };
            }
            return {
                month_count: this.month_count,
                tariff: this.tariff.code,
                executors_count: this.executors,
                managers_count: this.managers,
                opentasks_count: this.opentasks,
                fs_size: this.fs_size,
                has_expert: this.expert,
            };
        },

        update() {
            if (this.executors < this.minExecutors) {
                this.executors = this.minExecutors;
            }
            if (this.executors > this.maxExecutors) {
                this.executors = this.maxExecutors;
            }

            if (this.managers < this.minManagers) {
                this.managers = this.minManagers;
            }
            if (this.managers > this.maxManagers) {
                this.managers = this.maxManagers;
            }

            if (this.opentasks_count < this.minOpenTasks) {
                this.opentasks_count = this.minOpenTasks;
            }
            if (this.opentasks_count > this.maxOpenTasks) {
                this.opentasks_count = this.maxOpenTasks;
            }

            if (this.fs_size < this.minFS) {
                this.fs_size = this.minFS;
            }
            if (this.fs_size > this.maxFS) {
                this.fs_size = this.maxFS;
            }

            this.updateReceipt(this.payload());
        },

        close() {
            this.cleanReceipt();
            this.$emit('close');
        },

        submit() {
            this.createPayment(this.payload()).then(() => {
                this.$emit('close');
            });
        },
    },
};
</script>
