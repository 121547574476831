<template>
    <div class="form-group">
        <label class="form-label">Логин</label>
        <input class="form-input" type="text" disabled
            v-model="info.username" placeholder="Логин пользователя">
        <label class="form-label">Имя</label>
        <input class="form-input" type="text" :disabled="disabled"
            v-model="info.firstName" placeholder="Имя пользователя">
        <label class="form-label">Фамилия</label>
        <input class="form-input" type="text" :disabled="disabled"
            v-model="info.lastName" placeholder="Фамилия пользователя">
        <label class="form-label">Электронная почта</label>
        <input class="form-input" type="text" disabled
            v-model="info.email" placeholder="Электронная почта">
    </div>
</template>

<script>
import _pick from 'lodash/pick';

export default {
    name: 'UserProfileForm',
    props: ['user', 'disabled'],
    data() {
        return {
            info: _pick(this.user, [
                'id', 'username', 'firstName', 'lastName', 'email',
            ]),
        };
    },
    computed: {
        changed() {
            return this.user != null && (
                this.info.firstName !== this.user.firstName
                || this.info.lastName !== this.user.lastName
                || this.info.email !== this.user.email);
        },
    },
    watch: {
        changed(vv) {
            this.$emit('profile-changed', vv);
        },
    },
};
</script>
