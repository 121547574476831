<template>
    <!-- Модальное окно подтверждения удаления -->
    <div class="modal" :class="{ 'active': show }">
        <div @click="hide()" class="modal-overlay" aria-label="Close"></div>
        <form class="modal-container" @submit.prevent="submit()">
            <div class="modal-header text-center">
                <a @click="hide()" class="btn btn-clear float-right"
                    aria-label="Close"></a>
                <div class="modal-title">Выгрузка данных</div>
            </div>
            <div class="modal-body text-left">
                <div class="content form">
                    <h3>Укажите период</h3>
                    <div class="form-group">
                        <label class="form-label">Год</label>
                        <select class="form-select" v-model="year">
                            <option v-for="y in years"
                                :key="y" :value="y">{{y}}</option>
                        </select>
                        <label class="form-label">Месяц</label>
                        <select class="form-select" v-model="month">
                            <option v-for="m in months"
                                :key="m.id" :value="m.id">{{m.name}}</option>
                        </select>
                    </div>
                </div>
            </div>
            <div class="modal-footer text-right">
                <button class="btn mx-2" type="button" @click="hide()">Отмена</button>
                <button class="btn btn-primary" type="submit"
                    :disabled="month == undefined">Скачать</button>
            </div>
        </form>
    </div>

</template>

<script>

const MONTHS = [
    { id: 11, name: 'Декабрь' },
    { id: 10, name: 'Ноябрь' },
    { id: 9, name: 'Октябрь' },
    { id: 8, name: 'Сентябрь' },
    { id: 7, name: 'Август' },
    { id: 6, name: 'Июль' },
    { id: 5, name: 'Июнь' },
    { id: 4, name: 'Май' },
    { id: 3, name: 'Апрель' },
    { id: 2, name: 'Март' },
    { id: 1, name: 'Февраль' },
    { id: 0, name: 'Январь' },
];

export default {
    name: 'ExportXlsxModal',

    data() {
        return {
            show: false,
            years: [],
            months: [],
            year: 2017,
            last_year: 2017,
            last_month: 1,
            month: 1,
        };
    },

    watch: {
        year() {
            this.months = MONTHS
                .filter((m) => (this.year < this.last_year || this.last_month >= m.id));
            this.month = this.months[0].id;
        },
    },

    methods: {
        open() {
            const now = new Date();
            const year = now.getFullYear();
            const month = now.getMonth();

            this.last_year = year;
            this.last_month = month;

            this.years = Array.from({ length: year - 2016 }, (v, i) => 2017 + i);
            this.year = year;

            this.show = true;
        },
        hide() { this.show = false; },
        submit() {
            this.$emit('export', { year: this.year, month: this.month + 1 });
        },
    },
};

</script>
