<template>
    <div class="panel">
        <div class="panel-header">
            <div class="panel-title h4">Список платежей</div>
        </div>
        <div class="loading loading-lg" v-if="!paymentsIsLoaded" />
        <div v-else class="panel-body">
            <payment-ref v-for="payment in paymentsList" :key="payment.id"
                :payment="payment">
            </payment-ref>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.panel{
    .panel-body {
        padding-bottom: 2em;
    }
}
</style>
<script>
import { mapState } from 'vuex';

import PaymentRef from './PaymentRef.vue';

export default {
    name: 'OwnerPayments',
    components: { PaymentRef },

    computed: {
        ...mapState('udetails/payments', {
            paymentsList: 'payments',
            paymentsIsLoaded: 'isLoaded',
        }),
    },
};
</script>
