import Api from '@/api';

import * as M from './mutations';
import * as A from './actions';

const initState = {
    isLoaded: false,
    payments: [],
};

const getters = {};

const mutations = {
    [M.LOAD](state, list) {
        state.payments = list;
        state.isLoaded = true;
    },
    [M.RESET](state) {
        state.payments = [];
        state.isLoaded = false;
    },
};

const actions = {
    [A.UPDATE_LIST]({ commit }, ownerID) {
        commit(M.RESET);
        return new Promise((resolve, reject) => {
            Api.ownerPaymentsList(ownerID).then((resp) => {
                commit(M.LOAD, resp.data);
                resolve();
            }).catch(reject);
        });
    },

    [A.CLEAN_LIST]({ commit }) {
        commit(M.RESET);
    },
};

export default {
    namespaced: true,
    state: initState,
    getters,
    actions,
    mutations,
};
