<template>
    <div class="panel" v-if="info">
        <div class="panel-header">
            <div class="panel-title h4">Информация о клиенте</div>
        </div>
        <div class="panel-body text-left container">
            <div class="columns" v-if="info.agent">
                <div class="col-mr-auto text-italic">Агент</div>
                <div class="col-ml-auto text-right">{{info.agent}}</div>
            </div>
            <div class="columns">
                <div class="col-mr-auto text-italic">Логин</div>
                <div class="col-ml-auto text-right">{{info.username}}</div>
            </div>
            <div class="columns" v-if="info.firstname">
                <div class="col-mr-auto text-italic">Имя</div>
                <div class="col-ml-auto">{{info.firstname}}</div>
            </div>
            <div class="columns" v-if="info.lastname">
                <div class="col-mr-auto text-italic">Фамилия</div>
                <div class="col-ml-auto">{{info.lastname}}</div>
            </div>
            <div class="columns">
                <div class="col-mr-auto text-italic">E-mail</div>
                <div class="col-ml-auto">{{info.email}}</div>
            </div>

            <div class="divider"></div>

            <div class="columns">
                <div class="col-mr-auto text-italic">Количество организаций</div>
                <div class="col-ml-auto">{{info.stats.org_count}}</div>
            </div>
            <div class="columns">
                <div class="col-mr-auto text-italic">Число активных исполнителей</div>
                <div class="col-ml-auto">{{info.stats.executor_count}}</div>
            </div>
            <div class="columns">
                <div class="col-mr-auto text-italic">Число активных менеджеров</div>
                <div class="col-ml-auto">{{info.stats.manager_count}}</div>
            </div>
            <div class="columns">
                <div class="col-mr-auto text-italic">Число анонимных исполнений</div>
                <div class="col-ml-auto">{{info.stats.opentask_count}}</div>
            </div>
            <div class="columns">
                <div class="col-mr-auto text-italic">Размер файлового хранилища</div>
                <div class="col-ml-auto">{{info.stats.fs_size}}</div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'OwnerPanel',
    props: ['info'],
};
</script>
