<template>
    <div class="columns form-group">
        <div class="column col-auto">
            <button class="btn btn-primary"
                :disabled="minusDisabled" @click="minus()">
                <i class="icon icon-minus"></i>
            </button>
        </div>
        <div class="column text-center">
            <span class="form-label">
                {{vv}} <slot />
            </span>
        </div>
        <div class="column col-auto">
            <button class="btn btn-primary"
                :disabled="plusDisabled" @click="plus()">
                <i class="icon icon-plus"></i>
            </button>
        </div>
    </div>
</template>

<script>
export default {
    name: 'NumberFormItem',
    props: {
        value: Number,
        min: {
            type: Number,
            default: null,
        },
        max: {
            type: Number,
            default: null,
        },
        step: {
            type: Number,
            default: 1,
        },
    },

    data() {
        return {
            vv: this.value,
        };
    },

    computed: {
        plusDisabled() {
            return this.max != null && this.vv + this.step > this.max;
        },

        minusDisabled() {
            return this.min != null && this.vv - this.step < this.min;
        },
    },

    watch: {
        value(v) {
            this.vv = v;
        },
    },

    methods: {
        minus() {
            if (this.min == null || this.vv - this.step >= this.min) {
                this.vv -= this.step;
                this.$emit('input', this.vv);
                this.$emit('change');
            }
        },

        plus() {
            if (this.max == null || this.vv + this.step <= this.max) {
                this.vv += this.step;
                this.$emit('input', this.vv);
                this.$emit('change');
            }
        },
    },
};
</script>
