import isDate from 'lodash/isDate';
import isNumber from 'lodash/isNumber';
import fecha from 'fecha';

import Vue from 'vue';
import Store from '@/store';
import Router from '@/router';

import App from './App.vue';

Vue.config.productionTip = false;

const i18ru1 = {
    monthNames: ['января', 'февраля', 'марта', 'апреля', 'мая', 'июня', 'июля', 'августа', 'сентября', 'октября', 'ноября', 'декабря'],
};

function formatDate(d) {
    return fecha.format(d, 'DD MMMM YYYY', i18ru1);
}

// Фильтр для отображения дат
Vue.filter('formatDate', (value) => {
    if (isDate(value)) {
        return formatDate(value);
    }
    if (isNumber(value)) {
        return formatDate(new Date(value));
    }
    return '';
});

// Фильтр для отображения имен пользователей
Vue.filter('userFullName', (user) => {
    if (!user || !user.username) {
        return 'none';
    }
    const name = [user.firstName, user.lastName].filter((s) => s != null).join(' ');
    return name.length > 2 ? name : user.username;
});

new Vue({
    store: Store,
    router: Router,
    render: (h) => h(App),
}).$mount('#app');
