<template>
    <!-- Модальное окно ввода суммы -->
    <div class="modal" :class="{ 'active': show }">
        <div @click="hide()" class="modal-overlay" aria-label="Close"></div>
        <form class="modal-container">
            <div class="modal-header">
                <a @click="hide()" class="btn btn-clear float-right" aria-label="Close"></a>
                <div class="modal-title text-center h5">
                    Введите код
                </div>
            </div>
            <div class="modal-body content text-left">
                <div class="has-icon-right form-group" :class="{
                        'has-error': checking == 'wrong',
                        'has-success': checking == 'good'
                    }">
                    <input class="form-input" type="text"
                        v-model="code" placeholder="Код">
                    <i v-if="checking == 'load'" class="form-icon loading"></i>
                    <span class="form-input-hint">{{checkMsg}}</span>
                </div>
            </div>
            <div class="modal-footer">
                <button class="btn btn-primary"
                    @click="submitCreateCode"
                    :disabled="!canCreate"
                    type="button">Готово</button>
            </div>
        </form>
    </div>
</template>

<script>

import _debounce from 'lodash/debounce';

import Api from '@/api';

export default {
    name: 'AgentCodeModal',

    data() {
        return {
            show: false,
            checking: null,
            checkMsg: null,
            code: '',
        };
    },

    computed: {
        canCreate() {
            if (this.code == null || this.code.length < 1) {
                return false;
            }
            return this.checking === 'good';
        },
    },

    created() {
        this.debounceCheckCode = _debounce(this.checkCode, 200);
    },

    watch: {
        code(code) {
            this.checking = 'load';
            this.checkMsg = null;
            this.debounceCheckCode(code);
        },
    },

    methods: {
        checkCode(code) {
            if (!code) {
                this.checkMsg = 'Код типа не может быть пустым';
                this.checking = 'wrong';
                return;
            }
            Api.validateReferCode({ code }).then((resp) => {
                if (resp.data === 'OK') {
                    this.checking = 'good';
                    this.checkMsg = null;
                } else {
                    this.checking = 'wrong';
                    this.checkMsg = 'Данный код уже используется в системе';
                }
            }).catch((e) => {
                this.checking = 'wrong';
                this.checkMsg = e.message;
            });
        },

        open() {
            this.show = true;
            this.checking = null;
            this.checkMsg = null;
            this.code = '';
        },

        hide() {
            this.show = false;
        },

        submitCreateCode() {
            const payload = { code: this.code };
            this.$emit('new-code', payload);
            this.hide();
        },
    },
};
</script>
