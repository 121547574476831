import Api from '@/api';

import * as A from './actions';

const UPDATE_PROFILE = 'UPDATE_PROFILE';

const init = {
    user: undefined,
    lastTouch: 0,
};

const getters = {
    profile: (state) => state.user,

    avatarURL(s) {
        return (s.user && s.user.has_avatar) ? `/api/user/${s.user.username}/avatar.png?t=${s.lastTouch}` : '/avatar.png';
    },
};

const mutations = {
    [UPDATE_PROFILE](state, userinfo) {
        state.user = userinfo;
        state.lastTouch = new Date().getTime();
    },
};

const actions = {
    [A.UPDATE_INFO]({ state, commit }, userinfo) {
        const { username } = state.user;
        return new Promise((resolve, reject) => {
            Api.updateProfile(username, userinfo).then((resp) => {
                commit(UPDATE_PROFILE, resp.data);
                resolve();
            }).catch(reject);
        });
    },

    [A.UPDATE_AVATAR]({ state, commit }, avatarFile) {
        const { username } = state.user;
        return new Promise((resolve, reject) => {
            const data = new FormData();
            data.append('file', avatarFile);
            Api.updateAvatar(username, data).then(() => {
                Api.profile().then((resp) => {
                    commit(UPDATE_PROFILE, resp.data);
                    resolve();
                }).catch(reject);
            }).catch(reject);
        });
    },

    /* eslint-disable no-empty-pattern */
    [A.UPDATE_PASSWORD]({}, password) {
        return new Promise((resolve, reject) => {
            Api.updatePassword(password).then(resolve).catch((error) => {
                if (error.response.status === 400) {
                    reject(new Error('Неверно введен старый пароль'));
                } else {
                    reject(new Error('Произошла неизвестная ошибка'));
                }
            });
        });
    },
};

export default {
    namespaced: true,
    state: init,
    getters,
    actions,
    mutations,
};
