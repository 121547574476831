import { mapActions, mapState } from 'vuex';
import { UPDATE_LIST, CHANGE_SIZE, CHANGE_PAGE } from '@/store/agents/actions';

import AppLoading from '@/components/common/AppLoading.vue';
import TablePageSize from '@/components/common/TablePageSize.vue';
import TablePagination from '@/components/common/TablePagination.vue';

import AgentRef from './AgentRef.vue';

export default {
    name: 'OwnersList',
    components: {
        AppLoading, TablePageSize, TablePagination, AgentRef,
    },

    data() {
        return {
            isLoading: true,
        };
    },

    computed: {
        ...mapState('agents', {
            agentsList: 'list',
            agentsPage: 'pages',
        }),
    },

    methods: {
        ...mapActions('agents', {
            fetchAgentsList: UPDATE_LIST,
            changePage: CHANGE_PAGE,
            changeSize: CHANGE_SIZE,
        }),
    },

    mounted() {
        this.isLoading = true;
        this.fetchAgentsList().then(() => {
            this.isLoading = false;
        });
    },
};
