import { mapActions, mapState } from 'vuex';
import {
    UPDATE_LIST, CHANGE_SIZE, CHANGE_PAGE, FILTER_SEARCH,
} from '@/store/owners/actions';

import Api from '@/api';

import AppLoading from '@/components/common/AppLoading.vue';
import TablePageSize from '@/components/common/TablePageSize.vue';
import TablePagination from '@/components/common/TablePagination.vue';
import SearchBox from '@/components/common/SearchBox.vue';

import ExportXlsxModal from './ExportXlsxModal.vue';

export default {
    name: 'OwnersList',
    components: {
        AppLoading, TablePageSize, TablePagination, ExportXlsxModal, SearchBox,
    },

    data() {
        return {
            isLoading: true,
            filterSearch: null,
        };
    },

    computed: {
        ...mapState('owners', {
            ownersList: 'list',
            ownersPage: 'pages',
            ownersFilters: 'filters',
        }),
    },

    watch: {
        ownersFilters: 'reloadFilters',
    },

    methods: {
        ...mapActions('owners', {
            fetchOwnersList: UPDATE_LIST,
            changePage: CHANGE_PAGE,
            changeSize: CHANGE_SIZE,
            setFilterSearch: FILTER_SEARCH,
        }),

        reloadFilters(filters) {
            this.filterSearch = (filters && filters.search) || null;
        },

        downloadReport({ year, month }) {
            this.$refs.exportModal.hide();
            const url = this.$router.resolve({
                path: Api.exportUrl(year, month),
            });
            window.open(url.href, '_blank');
        },
    },

    mounted() {
        this.isLoading = true;
        this.fetchOwnersList().then(() => {
            this.reloadFilters(this.ownersFilters);
            this.isLoading = false;
        });
    },
};
