import Vue from 'vue';
import Router from 'vue-router';

// import WorkInProgress from '@/components/common/WorkInProgress';
import AppUserProfile from '@/components/main/AppUserProfile.vue';
import OwnersList from '@/components/owners/OwnersList.vue';
import OwnerInfo from '@/components/udetails/OwnerInfo.vue';

import AgentsList from '@/components/agents/AgentsList.vue';
import AgentInfo from '@/components/agents/AgentInfo.vue';
import NewAgent from '@/components/agents/NewAgent.vue';

Vue.use(Router);

export default new Router({
    mode: 'history',
    routes: [
        { path: '/', name: 'owners', component: OwnersList },
        { path: '/profile', name: 'profile', component: AppUserProfile },
        { path: '/agents', name: 'agents-list', component: AgentsList },
        { path: '/new-agent', name: 'create-agent', component: NewAgent },
        {
            path: '/agents/:agentID', name: 'agent-info', component: AgentInfo, props: true,
        },
        {
            path: '/owner/:ownerID', name: 'owner-info', component: OwnerInfo, props: true,
        },
        { path: '/*', redirect: '/' },
    ],
});
