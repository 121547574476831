import Api from '@/api';

import * as M from './mutations';
import * as A from './actions';

import payments from './payments';
import tariffs from './tariffs';

const initState = {
    details: null,
    upgradeReceipt: null,
    buyReceipt: null,
};

const getters = {};

const mutations = {
    [M.LOAD](state, details) {
        state.details = details;
        state.upgradeReceipt = null;
        state.buyReceipt = null;
    },
    [M.SET_BUY_RECEIPT](state, receipt) {
        state.upgradeReceipt = null;
        state.buyReceipt = receipt;
    },
    [M.SET_UPGRADE_RECEIPT](state, receipt) {
        state.upgradeReceipt = receipt;
        state.buyReceipt = null;
    },
    [M.RESET_RECEIPTS](state) {
        state.upgradeReceipt = null;
        state.buyReceipt = null;
    },
    [M.RESET](state) {
        state.details = null;
        state.upgradeReceipt = null;
        state.buyReceipt = null;
    },
};

const actions = {
    [A.UPDATE_INFO]({ commit, dispatch }, id) {
        return new Promise((resolve, reject) => {
            Api.ownerInfo(id).then((resp) => {
                commit(M.LOAD, resp.data);

                dispatch('payments/UPDATE_LIST', id);
                dispatch('tariffs/UPDATE_LIST');

                resolve();
            }).catch(reject);
        });
    },

    [A.CLEAN_INFO]({ commit }) {
        commit(M.RESET);
        commit('payments/RESET');
        commit('tariffs/RESET');
    },

    [A.COST_BUY_PAYMENT]({ commit, state }, payload) {
        const { id } = state.details;
        return new Promise((resolve, reject) => {
            Api.costBuyPayment(id, payload).then((resp) => {
                commit(M.SET_BUY_RECEIPT, resp.data);
                resolve();
            }).catch((err) => {
                commit(M.RESET_RECEIPTS);
                reject(err);
            });
        });
    },

    [A.COST_UPGRADE_PAYMENT]({ commit, state }, payload) {
        const { id } = state.details;
        return new Promise((resolve, reject) => {
            Api.costUpgradePayment(id, payload).then((resp) => {
                commit(M.SET_UPGRADE_RECEIPT, resp.data);
                resolve();
            }).catch((err) => {
                commit(M.RESET_RECEIPTS);
                reject(err);
            });
        });
    },

    [A.CLEAN_PAYMENTS]({ commit }) {
        commit(M.RESET_RECEIPTS);
    },

    [A.CREATE_BUY_PAYMENT]({ commit, state, dispatch }, payload) {
        const { id } = state.details;
        return new Promise((resolve, reject) => {
            Api.createBuyPayment(id, payload).then(() => {
                commit(M.RESET_RECEIPTS);
                dispatch('payments/UPDATE_LIST', id);
                resolve();
            }).catch(reject);
        });
    },

    [A.CREATE_UPGRADE_PAYMENT]({ commit, state, dispatch }, payload) {
        const { id } = state.details;
        return new Promise((resolve, reject) => {
            Api.createUpgradePayment(id, payload).then(() => {
                commit(M.RESET_RECEIPTS);
                dispatch('payments/UPDATE_LIST', id);
                resolve();
            }).catch(reject);
        });
    },

    [A.CONFIRM_PAYMENT]({ state, dispatch }, paymentID) {
        const { id } = state.details;
        return new Promise((resolve, reject) => {
            Api.confirmPayment(id, paymentID).then(() => {
                dispatch(A.UPDATE_INFO, id).then(resolve, reject);
            }).catch(reject);
        });
    },

    [A.CANCEL_PAYMENT]({ state, dispatch }, paymentID) {
        const { id } = state.details;
        return new Promise((resolve, reject) => {
            Api.cancelPayment(id, paymentID).then(() => {
                dispatch(A.UPDATE_INFO, id).then(resolve, reject);
            }).catch(reject);
        });
    },
};

export default {
    namespaced: true,
    state: initState,
    getters,
    actions,
    mutations,
    modules: {
        payments,
        tariffs,
    },
};
