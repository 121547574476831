<template>
    <div id="app">
        <app-header></app-header>
        <app-loading v-if="!isNotLogged && !isLogged"></app-loading>
        <app-login v-if="isNotLogged"></app-login>
        <div v-if="isLogged">
            <router-view />
        </div>
    </div>
</template>

<script>

import { mapGetters } from 'vuex';

import AppHeader from '@/components/main/AppHeader.vue';
import AppLogin from '@/components/main/AppLogin.vue';
import AppLoading from '@/components/common/AppLoading.vue';

export default {
    name: 'app',
    components: {
        AppHeader, AppLogin, AppLoading,
    },
    computed: {
        ...mapGetters(['isLogged', 'isNotLogged']),
    },
};
</script>

<style lang="scss">
// Глобально импортируем стили Spectre
@import "~spectre.css/src/spectre";
@import "~spectre.css/src/spectre-icons";

body {
    margin: 0;
    background-color: $gray-color-light;
}

#app {
    font-family: "Avenir", Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
}

.app-tile-icon {
    -ms-flex-align: center;
    -ms-flex-line-pack: distribute;

    align-items: center;
    justify-content: center;

    background: $primary-color;
    border-radius: .15rem;
    color: #fff;
    display: flex;
    display: -ms-flexbox;
    font-size: 0.9rem;
    height: 1.5rem;
    width: 1.5rem;
}

</style>
