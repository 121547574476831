import Api from '@/api';
import PagedList from '@/store/pagedlist';

import * as A from './actions';
import * as M from './mutations';

const page = new PagedList('pages');

const initState = {
    list: [],
    pages: page.initState(),
    filters: {},
};

const getters = { };

const mutations = {
    [M.LOAD](state, { list, pagination, filters }) {
        state.list = list;
        state.pages = page.readState(pagination);
        state.filters = filters;
    },
    [M.RESET](state) {
        state.list = [];
        state.pages = page.initState();
        state.filters = {};
    },
};

const actions = {
    ...page.mapActions(),

    [A.UPDATE_LIST]({ dispatch, state }) {
        return page.obsolete(state) ? dispatch(A.LOAD_LIST) : Promise.resolve();
    },

    [A.LOAD_LIST]({ commit, state }, opts) {
        const pageOpts = page.pageOpts(state, opts);
        return new Promise((resolve, reject) => {
            Api.listOwners(pageOpts).then((resp) => {
                commit(M.LOAD, resp.data);
                resolve(resp.data);
            }).catch((err) => {
                commit(M.RESET);
                reject(err);
            });
        });
    },

    [A.FILTER_SEARCH]({ dispatch }, value) {
        dispatch(value ? A.SET_FILTER : A.CLEAR_FILTER, { filter: 'search', value });
    },
};

export default {
    namespaced: true,
    state: initState,
    getters,
    actions,
    mutations,
};
