<template>
    <div class="container">
        <div class="columns">
            <div class="col-12 text-center text-italic">
                c {{receipt.from_datetime | formatDate}}
                по {{receipt.to_datetime | formatDate}}
            </div>
        </div>
        <div class="columns" v-if="receipt.tariff">
            <div class="col-mr-auto text-italic">Тариф</div>
            <div class="col-ml-auto text-right">
                {{receipt.tariff.scale}} x {{receipt.tariff.per_month}}
                = {{receipt.tariff.cost}} руб.
            </div>
        </div>
        <div class="columns" v-if="receipt.executors">
            <div class="col-mr-auto text-italic">
                Доп. исполнители x {{receipt.executors.count}}
            </div>
            <div class="col-ml-auto text-right">
                {{receipt.executors.scale}}
                x {{receipt.executors.per_month * receipt.executors.count}}
                = {{receipt.executors.cost}} руб.
            </div>
        </div>
        <div class="columns" v-if="receipt.managers">
            <div class="col-mr-auto text-italic">
                Доп. менеджеры x {{receipt.managers.count}}
            </div>
            <div class="col-ml-auto text-right">
                {{receipt.managers.scale}}
                x {{receipt.managers.per_month * receipt.managers.count}}
                = {{receipt.managers.cost}} руб.
            </div>
        </div>
        <div class="columns" v-if="receipt.opentasks">
            <div class="col-mr-auto text-italic">
                Доп. исполнения x {{receipt.opentasks.count * 100}}
            </div>
            <div class="col-ml-auto text-right">
                {{receipt.opentasks.scale}}
                x {{receipt.opentasks.per_month * receipt.opentasks.count}}
                = {{receipt.opentasks.cost}} руб.
            </div>
        </div>
        <div class="columns" v-if="receipt.fs">
            <div class="col-mr-auto text-italic">
                Доп. ФС x {{receipt.fs.count}}
            </div>
            <div class="col-ml-auto text-right">
                {{receipt.fs.scale}}
                x {{receipt.fs.per_month * receipt.fs.count}}
                = {{receipt.fs.cost}} руб.
            </div>
        </div>
        <div class="columns" v-if="receipt.expert">
            <div class="col-mr-auto text-italic">
                Помощь эксперта
            </div>
            <div class="col-ml-auto text-right">
                {{receipt.expert.scale}} x {{receipt.expert.per_month}}
                = {{receipt.expert.cost}} руб.
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ReceiptInfo',
    props: ['receipt'],

};
</script>
