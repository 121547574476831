import Api from '@/api';

import * as M from './mutations';
import * as A from './actions';

const initState = {
    isLoaded: false,
    tariffs: [],
};

const getters = {};

const mutations = {
    [M.LOAD](state, { tariffs }) {
        state.tariffs = tariffs;
        state.isLoaded = true;
    },
    [M.RESET](state) {
        state.tariffs = [];
        state.isLoaded = false;
    },
};

const actions = {
    [A.UPDATE_LIST]({ commit, state }) {
        if (state.isLoaded) {
            return Promise.resolve();
        }

        return new Promise((resolve, reject) => {
            Api.listTariffs().then((resp) => {
                commit(M.LOAD, resp.data);
                resolve();
            }).catch(reject);
        });
    },

    [A.CLEAN_LIST]({ commit }) {
        commit(M.RESET);
    },
};

export default {
    namespaced: true,
    state: initState,
    getters,
    actions,
    mutations,
};
